import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Text } from 'theme-ui'

const PagePrivacyNotice = props => {
  return (
    <Layout {...props}>
      <Seo title='Privacy Notice' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Privacy Notice'
          subheader=''
        />
      </Stack>
      <Stack>
        <Main>
          <Text variant='p'>Pinout respects the privacy of our visitors and is committed to protecting the personal information that you may provide to us. We do not collect personal information from our users, however, our third party providers, such as Google Adsense, may collect or use information to target ads.</Text>
          <Text variant='h3'>Third Party Providers</Text>
          <Text variant='p'>We may use third-party service providers to serve ads on our behalf across the Internet and sometimes on this site. They may collect anonymous information about your visits to our website, and your interaction with our products and services. No personally identifiable information is collected or used in this process. They do not know the name, phone number, address, email address, or any personally identifying information about the user.</Text>
          <Text variant='h3'>Changes to our Privacy Notice</Text>
          <Text variant='p'>Pinout reserves the right to make changes to this Privacy Notice at any time and for any reason. We will notify you of any changes to our Privacy Notice by posting the new Privacy Notice on this page. Your continued use of the website following the posting of changes to this policy will mean you accept those changes.</Text>
          <Text variant='p'>Thank you for using Pinout!</Text>
        </Main>
      </Stack>
    </Layout>
  )
}

export default PagePrivacyNotice
